export const plansData = [
  {
    id: 1,
    name: 'Plan Emprendedor',
    href: '/',
    category: 'Corredor principiante',
    description:
      'Ideal para corredores que viene integrandose al mundo de corretaje y canje, con ganas de EMPRENDER.',
      itemList: [
        'Cantidad inicial de cupo de canje.',
        'Plataforma de gestión y administración.',
        'App móvil',   
        'Reportes (Resumen de tus movimientos, venta, arriendo, canjes).',
        'Soporte general.',

      ] 
  },
  {
    id: 2,
    name: 'Plan Medio',
    href: '/',
    category: 'Corredor mediano',
    description:
      'Ideal para corredores con una MEDIANA experiencia dentro del mundo del correctaje.',
    itemList: [
      'Cantidad median de cupo de canje.',
      'Plataforma de gestión y administración.',
      'App móvil',
      'Reportes (Resumen mensual de tus movimientos, venta, arriendo, canjes).',
      'Soporte mensual.',

    ],
  },
  {
    id: 3,
    name: 'Plan Avanzado',
    href: '/',
    category: 'Corredor avanzado',
    description:
      'Ideal para corredores con una AVANZADA experiencia dentro del mundo del corretaje y sistema de canje.',
    itemList: [ 
      'Cantidad aumentada de cupo de canje.',
      'Plataforma de gestión y administración.',
      'App móvil',
      'Reportes (Resumen semanal de tus movimientos, venta, arriendo, canjes).',
      'Valoración entre corredores.',
      'Soporte semanal.',
      'Capacitación incluida.',
      'Accesos a servicios externos'
    ],
  },
  {
    id: 4,
    name: 'Plan Premium',
    href: '/',
    category: 'Corredor premium',
    description:
      'Ideal para Corredores con un manejo de nivel EXPERTO dentro del corretaje, para esto habilita el Premium de la aplicación.',
    itemList: [
      'Cantidad ilimitada de cupo de canje.',
      'Plataforma de gestión y administración.',
      'App móvil',
      'Reportes (Resumen diario de tus movimientos, venta, arriendo, canjes).',
      'Valoración entre corredores.',
      'Soporte diario.',
      'Capacitación incluida.',
      'Accesos a servicios externos PREMIUM.'
    ],
  },
];
