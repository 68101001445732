// import { FaTruckMoving, AiFillSafetyCertificate } from '../components/icon';

export const navigationData = [
  {
    id: 1,
    name: 'Inicio',
    href: '/',
  },
  {
    id: 2,
    name: 'Servicios',
    href: '/servicios',  
  },
  {
    id: 3,
    name: 'Cómo funciona',
    href: '/como-funciona',  
  },
  {
    id: 4,
    name: 'Nosotros',
    href: '/nosotros',  
  },
  {
    id: 5,
    name: 'FAQ',
    href: '/faq',
  },
  {
    id: 6,
    name: 'Mi cuenta',
    href: 'https://qr-service-dashboard.netlify.app/auth-login',
  },
];
